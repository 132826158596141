import React from "react"
import moment from "moment"
import "./Blog.scss"
import Avatar from "../../assets/img/avatar.png"
import categoryData from "../../data/categories.banner.json"
import { Link } from "gatsby"

const BlogHeader = props => {
  const { title, author, publishedAt, currentCategory } = props
  let matchedCategory =
    categoryData.find(x => x.slug === currentCategory) || categoryData[0]
  return (
    <div className="blog-header">
      <div className="flex-left">
        <div className="blog-header__navigation">
          <Link to="/">Home</Link>{" "}
          <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
          <Link to="/blog">Blog</Link>{" "}
          <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
          <Link to={`/categories/${matchedCategory.slug}`}>
            {matchedCategory.title}
          </Link>{" "}
          <span className="blog-header__navigation__customSymbol">{">"}</span>{" "}
          {title}
        </div>
        <div className="blog-header__title">
          <h1 id="heading-title-blog" className="fix-orphan black">{title}</h1>
        </div>
        <div className="blog-header__author d-flex">
          <img
            className="blog-header__author__avatar"
            src={author.avatar.url || Avatar}
            alt="author"
          />
          <div className="blog-header__author__info">
            <p className="blog-header__author__info__name">{author.name}</p>
            <p className="blog-header__author__info__date">
              {moment(new Date(publishedAt)).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogHeader
