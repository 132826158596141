import React from "react"
import "./Blog.scss"
import "./content-styles.css"

const BlogContent = props => {
  const { content } = props
  const convertNewContent = content?.replace(/\/wp-content\/uploads\//g,
      `${process.env.PUBLIC_IMAGE_URL}/wp-content/uploads/`)
  return (
    <div className="blog-content ck-content">
      <div className="ck-zone" dangerouslySetInnerHTML={{ __html: convertNewContent }} />
    </div>
  )
}

export default BlogContent
