import React from "react"
import SEO from "../components/seo"
import Header from "../components/Navbar/Navbar.js"
import Footer from "../components/Footer/Footer.js"
import BlogHeader from "../components/Blog/BlogHeader"
import BlogContent from "../components/Blog/BlogContent"
import { scrollTop } from "../components/layout"
import Scroll from "../components/ScrollTop/Scroll"

const BlogTemplate = ({ pageContext }) => {
  const blog = pageContext.blogData
  const { seo } = blog

  return (
    <>
      <SEO
        type="article"
        title={seo.title || blog.title}
        description={seo.metaDesc}
        metaKeywords={blog.focuskw}
        thumbnail={blog.featuredImage?.node.sourceUrl}
      />
      <Header />
      <div id="blog">
        <div className="blog-area wrapper">
          <div className="margin-top">
            <BlogHeader
              title={blog.title}
              author={blog.author.node}
              publishedAt={blog.date}
              currentCategory={blog.categories.nodes[0].slug}
            />
          </div>
          <BlogContent content={blog.content} />
        </div>
      </div>
      <Footer />
      <Scroll showBelow={scrollTop} />
    </>
  )
}

export default BlogTemplate
